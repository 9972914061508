const FooterData = [{
  "header": "Business",
  "links": [
    {"title": "Products", "link": "/products"},
    {"title": "Projects", "link": "/projects"},
    {"title": "About us", "link": "/about"},
    {"title": "Certificates ", "link": "/about"},
    ]
},
];

export default FooterData;
