import classes from "./products.module.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import products from "../../../assets/data/ProductsData";
import { useNavigate } from 'react-router-dom';

function Products() {
  let navigate = useNavigate();
  return (
    <div className={classes.container}>
      <div className={classes.products}>
        <h1 className={classes.title}>Our Products</h1>
        <AliceCarousel
          className={classes.carousel}
          mouseTracking
          items={products?.slice(0,4).map((card) => (
            <div className={classes.highlightCard}>
              <img src={card.img} className={classes.highlightImg} alt="h" />
              <div className={classes.highlightInfo}>
                <h2>{card.title}</h2>
                <p>{card.info}</p>
              </div>
            </div>
          ))}
          autoPlay={true}
          autoPlayInterval={1000}
          autoPlayStrategy={"action"}
          responsive={{
            0: {
              items: 2,
            },
            1024: {
              items: 4,
            },
          }}
          disableDotsControls={true}
          renderPrevButton={(e) => {
            return (
              <ArrowBackIosNewIcon
                style={{ fontSize: "30" }}
                className={
                  e.isDisabled ? classes.carouseLBtnDis : classes.carouseLBtn
                }
              />
            );
          }}
          renderNextButton={(e) => {
            return (
              <ArrowForwardIosIcon
                style={{ fontSize: "30" }}
                className={
                  e.isDisabled ? classes.carouselRtnDis : classes.carouselRBtn
                }
              />
            );
          }}
        />
        <div className={classes.btn}>
          <button onClick={()=>{navigate("/products")}} >View All</button>
        </div>
      </div>
    </div>
  );
}

export default Products;
