import classes from './pageHeader.module.css';

function PageHeader(props) {
  return (
    <div className={classes.container}>
      <h1>{props.title}</h1>
    </div>
  );
}

export default PageHeader;