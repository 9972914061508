import classes from './aboutUs.module.css';
import homePage1 from "../../assets/imgs/home/homePage1.jpg";
import { useState } from 'react';
import Certificates from './certificates/certificates';

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function AboutUs() {
    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        {
            label: '1991',
            description: "KASSED AL KHAYER was established by the Chairman &Former Undersecretary of the Ministry of Military Production Mr. Nabil Abdul Ghani Al-Ashry, The aim of the company's establishment was to enter the Egyptian market in the steel industry for steel buildings factories and others",
        },
        {
            label: '1997',
            description: "The Engineering Group for formatting & machining minerals Company was established as an extension of the former entity With the required development to suit the current time period of the Egyptian market Through the Chairman and deputy Chairman Ahmed Nabil Al-Ashry A production activity has been developed and adds activity the formation and operation of metals and steel industries",
        },
        {
            label: '2006',
            description: "Engineering group for industry & contracting Another stretch of years of experience gained in the steel industry with the entry into a new challenge in the field of Design, manufacture and installation of electromechanical works for drinking water, sewage and irrigation",
        },
    ]

    return (
        <>
            <div className={classes.aboutUs}>
                <div className={classes.img}>
                    <img src={homePage1} alt="homePage1" />
                </div>
                <div className={classes.content}>
                    <div className={classes.timeLine}>
                        <ul>
                            {steps.map((step, index) => (
                                <li key={index} disabled={index !== activeStep} onClick={() => setActiveStep(index)}>{step.label}</li>))
                            }
                        </ul>
                    </div>
                    <div className={classes.text}>
                        <h2>About Us</h2>
                        <div className={classes.buttons}>
                            <button onClick={() => setActiveStep((activeStep - 1 + steps.length) % steps.length)}><ArrowBackIosNewIcon sx={{ color: 'white', fontSize: '2rem' }} /></button>
                            <p>{activeStep + 1} of {steps.length}</p>
                            <button onClick={() => setActiveStep((activeStep + 1) % steps.length)}><ArrowForwardIosIcon sx={{ color: 'white', fontSize: '2rem' }} /></button>
                        </div>
                        <div className={classes.paragraphs} data-active-step={activeStep}>
                            {steps.map((step, index) => (
                                <div key={index} className={`${classes.paragraph} animate__animated animate__fadeInUp`}>{step.description}</div>))
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Certificates />
        </>
    );
}

export default AboutUs;
