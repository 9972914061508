import { BiSolidPhoneCall } from "react-icons/bi";
import { FaFax } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { ImLocation2 } from "react-icons/im";
import { GiRotaryPhone } from "react-icons/gi";
const contactData = [
    [
        {title: "Address",
        data: "1 El-Fransawy st. of 26th July st. - Cairo – Egypt",
        icon: <ImLocation2/>},
        {title: "Telehone",
        data: "+20 225789056",
        icon: <GiRotaryPhone/>},
        {title: "Fax",
        data: "+20 225789056",
        icon: <FaFax/>}
    ],
    [
        {title: "Address",
        data: "15th of May city – zone 27- part 121 - Helwan – Cairo – Egypt",
        icon: <ImLocation2/>},
        {title: "Phone",
        data: "010 65529 542",
        icon: <BiSolidPhoneCall/>},
        {title: "Telephone",
        data: "02 25687275",
        icon: <GiRotaryPhone/>},
        {title: "Email",
        data: "morad@egindustry.com",
        icon: <IoMdMail/>},
    ],
    [
        {title: "Address",
        data: "15th of May city – zone 8 - block 7-  part 2 - Helwan – Cairo – Egypt",
        icon: <ImLocation2/>},
        {title: "Phone",
        data: "010 65529 542",
        icon: <BiSolidPhoneCall/>},
        {title: "Telephone",
        data: "02 25687275",
        icon: <GiRotaryPhone/>},
        {title: "Email",
        data: "morad@egindustry.com",
        icon: <IoMdMail/>},
    ]
]

export default contactData;