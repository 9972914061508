import p1 from "../imgs/certificates/Authority accreditation.jpg";
import p2 from "../imgs/certificates/ISO 5001.jpg";
import p3 from "../imgs/certificates/ISO 9001.jpg";

const projects = [
    {
      img: p1,
      title: "Authority accreditation",
    },
    {
      img: p2,
      title: "ISO 5001",
    },
    {
      img: p3,
      title: "ISO 9001",
    }
  ];

export default projects;
