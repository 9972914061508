import React, { useEffect } from "react";
import classes from "./projects.module.css";
import PageHeader from "../../generic components/pageHeader/PageHeader";
import projects from "../../assets/data/ProjectsData";
import moreProjects from "../../assets/data/MoreProjectsData";
import partners from "../../assets/data/PartnersData";
import "animate.css";

function ProjectsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(partners[0]);
  }, []);

  return (
    <div className={classes.projectsPage}>
      <PageHeader title="Projects" />
      <h1 className="title">Main Projects</h1>
      <div className={`${classes.cardsWrapper} animate__animated animate__fadeInUp`}>
        {projects.map((product, index) => {
          return (
            <div className={classes.highlightCard}>
              <img
                src={product.img}
                className={classes.highlightImg}
                alt="Projects"
              />
              <div className={classes.highlightInfo}>
                <h2>{product.title}</h2>
                <p>{product.info}</p>
              </div>
            </div>
          );
        })}
      </div>
      <h1 className="title">More Projects</h1>
      <div className={classes.cardsWrapper}>
        {moreProjects.map((product, index) => {
          return (
            <div className={classes.moreProjectsCard}>
                <div className={classes.moreProjectsInfo}>
                  <h2>{product.title}</h2>
                  <p>{product.info}</p>
                </div>
                <div className={classes.logoContainer}>
                  <img
                    src={partners[product.partner].img}
                    className={classes.highlightImg}
                    alt="Logo"
                  />
                </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProjectsPage;
