import React from "react";
import classes from "./partners.module.css";
import "../../../index.css"
import partners from "../../../assets/data/PartnersData";

const Partners = () => {
  return (
    <div className={classes.container}>
      <h1 className="title">Clients that trust us</h1>
      <div className={classes.partners}>
        {partners.slice(0,6).map((partner, index) => {
          return (
            <img
              src={partner.img}
              className={classes.partnerImg}
              alt="partner"
            />
          );
        })}
      </div>
    </div>
  );
};

export default Partners;
