import fab from '../imgs/services/fabrication.svg';
import install from '../imgs/services/installation.svg';
import test from '../imgs/services/testing.svg';

const servicesData = [
    {
        title: 'Fabrication',
        img: fab,
    },
    {
        title: 'Installation',
        img: install,
    },
    {
        title: 'Testing',
        img: test,
    },
]

export default servicesData;