const Projects = [
    {
        title: "El Galala",
        info: "Supply Tank-pipe and fitting with different diameters",
        partner: 6
    },
    {
        title: "Fostat garden",
        info: "Supply Electric penstock Supply Basket screen",
        partner: 1
    },
    {
        title: "New Delta – phase two",
        info: "Supply Pipes and fitting",
        partner: 1
    },
    {
        title: "88 – Alex project",
        info: "Fuel Tank for fueling station Capacity 90m3",
        partner: 7
    },
    {
        title: "Shobra El Khima WTP",
        info: "Supply and installation pipes and fitting up to 1800mm",
        partner: 5
    },
    {
        title: "Shama – Talia – Sobk El Ahd",
        info: "Supply Penstocks supply Metal works",
        partner: 5
    },
    {
        title: "Al jabl Al Asfr Project",
        info: "Supply Electric penstocks",
        partner: 2
    },
    {
        title: "Toshka Project",
        info: "Supply& installation pipes and fitting",
        partner: 2
    },
    {
        title: "Al Shorbgy project",
        info: "Supply pipes and fitting",
        partner: 2
    },
]

export default Projects;