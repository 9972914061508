import p1 from "../../assets/imgs/products/Grit removal.jpg";
import p2 from "../../assets/imgs/products/Sand Silo.jpg";
import p3 from "../../assets/imgs/products/belt conveyor.jpg";
import p4 from "../../assets/imgs/products/stop logs.jpg";
import p5 from "../../assets/imgs/products/sludge thickner.jpg";

const products = [
    {
      "img": p1,
      "title": "Grit removal",
      "info": "Separates valuable particles with precision.",
      "description": "**Efficiently removes impurities, paving the way for downstream operations.**"
    },
    {
      "img": p2,
      "title": "Sand Silo",
      "info": "Stores and releases granular materials.",
      "description": "**Versatile system for various applications, from construction to manufacturing.**"
    },
    {
      "img": p3,
      "title": "Belt conveyor",
      "info": "Moves materials reliably.",
      "description": "**Seamless flow, engineered for efficiency and reliability.**"
    },
    {
      "img": p4,
      "title": "Stop logs",
      "info": "Controls water flow precisely.",
      "description": "**Precision barriers for confident flow control and optimization.**"
    },
    {
      "img": p5,
      "title": "Sludge thickener",
      "info": "Condenses waste into manageable volumes.",
      "description": "**Transforms waste while minimizing environmental impact.**"
    }
  ];

export default products;