import p1 from "../imgs/projects/terminal3/img2.jpg";
import p2 from "../imgs/projects/elgalala/img3.jpg";
import p3 from "../imgs/projects/elarish/img1.jpg";

const projects = [
    {
      img: p1,
      title: "Terminal 3",
    },
    {
      img: p2,
      title: "El Galala",
    },
    {
      img: p3,
      title: "El Arish",
    },
  ];

  export default projects;