import dish from "../imgs/machinery/Dish.jpg";
import FlangeMachine from "../imgs/machinery/FlangeMachine.jpg";
import FlangeRoller from "../imgs/machinery/FlangeRoller.jpg";
import roller from "../imgs/machinery/Roller.jpg";
import blendingMachine from "../imgs/machinery/blendingMachine.jpg";
import HorizontalLathe from "../imgs/machinery/HorizontalLathe.jpg";
import SubmergedWelding from "../imgs/machinery/SumbergedWelding.jpg";
import VerticalLathe from "../imgs/machinery/VerticalLathe.jpg";
import Turning from "../imgs/machinery/ManualTurning.jpg";
import Turning2 from "../imgs/machinery/ManualTurning2.jpg";
import Shearing from "../imgs/machinery/Shearing.jpg";
import cnc from "../imgs/machinery/cnc.jpg";


const MachineryData = [
    {
        title: 'Dish End Machine',
        text: 'Diameter up to 5m with thickness up to 25mm',
        img: dish,
    },
    {
        title: 'Flange Roller Machine',
        text: 'From diameter 800mm to 3000mm',
        img: FlangeRoller,
    },
    {
        title: 'Flange Machine',
        text: 'For dish end with thickness up to 25mm',
        img: FlangeMachine,
    },
    {
        title: 'Horizontal Lathe Machine',
        text: 'For flanges up to diameter 1200mm',
        img: HorizontalLathe,
    },
    {
        title: 'Vertical Lathe Machine',
        text: 'For flanges up to diameter 3000mm',
        img: VerticalLathe,
    },
    {
        title: 'Submerged Welding Machine',
        text: 'Automatic with column boom 4m',
        img: SubmergedWelding,
    },
    {
        title: 'CNC Plasma Machine',
        img: cnc,
    },
    {
        title: 'Manual Turning Machine',
        img: Turning,
    },
    {
        title: 'Roller Machine',
        img: roller,
    },
    {
        title: 'Bending Machine',
        img: blendingMachine,
    },
    {
        title: 'Manual Turning Machine',
        img: Turning2,
    },
    {
        title: 'Shearing Machine',
        img: Shearing,
    }
]

export default MachineryData;