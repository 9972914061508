import classes from "./contactUs.module.css";
import contactData from "../../assets/data/ContactData";
import PageHEader from "../../generic components/pageHeader/PageHeader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ContactBox from "./contact box/ContactBox";

function ContactUs() {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Enter your first name"),
    email: Yup.string()
      .min(3, "Enter a valid email address")
      .email("Enter a valid email address")
      .required("Enter a valid email address"),

    // check for a valid egyptian mobile number

    phoneNo: Yup.string()
      .min(11, "Enter a valid phone number")
      .matches(/^(01)[0-9]{9}$/, "Enter a valid phone number")
      .required("Enter your phone number"),
    msg: Yup.string().required("Enter your message"),
  });

  onsubmit = (values) => {
    window.open(`mailto:morad@egindustry.com?subject=EG website contact us&body=First Name: ${values.firstName} %0D%0A Last Name: ${values.lastName} %0D%0A Email: ${values.email} %0D%0A Phone Number: ${values.phoneNo} %0D%0A%0D%0A ${values.msg}`)
  };
  return (
    <div>
      <PageHEader title="Contact Us" />
      <div className={classes.content}>
        <div className={classes.info}>
          <h1>Cairo Branch </h1>
          {contactData[0].map((item, index) => (
            <div key={index} className={`${classes.infoBox} animate__animated animate__fadeInLeft`}>
              <h4>
                {item.icon} {item.title}
              </h4>
              <h5>{item.data}</h5>
            </div>
          ))}
        </div>
        <div className={`${classes.form} animate__animated animate__fadeInRight`}>
          <h4>
            Leave us a message,
            <br /> we will get back to you as soon as we can
          </h4>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onsubmit}
          >
            {({ values }) => (
              <Form>
                <Field
                  className={classes.field}
                  name="firstName"
                  type="text"
                  autoComplete="off"
                  placeholder="First Name"
                />
                <ErrorMessage name="firstName" component="span" />
                <Field
                  className={classes.field}
                  name="lastName"
                  type="text"
                  autoComplete="off"
                  placeholder="Last Name"
                />
                <Field
                  className={classes.field}
                  name="email"
                  type="email"
                  autoComplete="off"
                  placeholder="Email"
                />
                <ErrorMessage name="email" component="span" />
                <Field
                  className={classes.field}
                  name="phoneNo"
                  type="text"
                  autoComplete="off"
                  placeholder="Phone Number"
                />
                <ErrorMessage name="phoneNo" component="span" />
                <Field
                  className={classes.field}
                  name="msg"
                  type="text"
                  autoComplete="off"
                  placeholder="Message..."
                />
                <ErrorMessage name="msg" component="span" />
                <div className={classes.btn}>
                  <button type="submit" className={classes.button}>
                    SEND
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className={classes.branches}>
        <ContactBox info={contactData[1]} index={1} map={"https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3461.6957061597027!2d31.382060975544935!3d29.815334275039778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjnCsDQ4JzU1LjIiTiAzMcKwMjMnMDQuNyJF!5e0!3m2!1sen!2seg!4v1708522888355!5m2!1sen!2seg"} />
        <ContactBox info={contactData[2]} index={2} map={"https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3461.8278413258213!2d31.351448075544727!3d29.811517675041788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjnCsDQ4JzQxLjUiTiAzMcKwMjEnMTQuNSJF!5e0!3m2!1sen!2seg!4v1708522375324!5m2!1sen!2seg"} />
      </div>
    </div>
  );
}

export default ContactUs;
