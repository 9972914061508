import React from "react";
import CarouselHeroSection from "./CarouselHeroSection/CarouselHeroSection";
import homePage1 from "../../assets/imgs/home/homePage1.jpg";
import homePage2 from "../../assets/imgs/home/homePage2.jpg";
import homePage3 from "../../assets/imgs/home/homePage3.jpg";
import Partners from "./Partners/Partners";
import Services from "./Services/Services";
import Machinery from "./Machinery/Machiney";
import Products from "./Products/products";
import Projects from "./Projects/Projects";

const HomePage = () => {
  return (
    <>
      <CarouselHeroSection
          title={["FAST AND RELIABLE SERVICES FOR YOUR PROJECT OR A QUICK FIX, ", "Boost Your Productivity with Our Industrial Services", "From concept to completion, we handle it all with speed and expertise"]}
          subTitle={["We Do it all!", "Quick Fixes, Expert Results!", "Speed, Precision, Efficiency!"]}
          images={[homePage1, homePage2, homePage3]}
          tintColor="var(--green)"
        />
        <Services />
        <Products />
        <Projects />
        <Machinery />
        <Partners />
    </>
  );
};

export default HomePage;
