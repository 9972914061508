import classes from "./certificates.module.css";
import certData from "../../../assets/data/certData"

function card(card){
    return (
        <div className={classes.cardWrapper}>
            <div className={classes.highlightCard}>
                <img src={card.img} className={classes.highlightImg} alt="h" />
                <h1>{card.title}</h1>
            </div>
        </div>
    )
}

export default function Certificates(){

    return (
        <>
            <h1 className={classes.title}>Certificates</h1>
            <div className={classes.certWrapper}>
                {certData.map((cert)=>
                    card(cert)
                )}
            </div>
        </>
    )
}
