import classes from "./CarouselHeroSection.module.css";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import 'animate.css'

export default function CarouselHeroSection(props) {
  function displayCarouselItems(images) {
    return images.map((item, index) => (
      <div className={classes.carouselItem}>
        <img
          key={index}
          src={item}
          alt="homeImage"
          className={`${classes.carouselImage} animate__animated animate__fadeIn`}
        />
        <div className={classes.heroContent}>
          {props.title && (
            <>
                <h1 className="animate__animated animate__fadeInUp"> {props.title[index]}</h1>
                <h2 className="animate__animated animate__fadeInUp"> {props.subTitle[index]}</h2>
            </>
          )}
        </div>
      </div>
    ));
  }
  function renderDotsItem(e) {
    return (
      <div
        className={`${e.isActive ? classes.active : ""} ${
          classes.carouselIndexBtn
        }`}
      ></div>
    );
  }

  function renderPrevButton(e) {
    return (
      <ArrowBackIosNewIcon
        style={{ fontSize: "50" }}
        className={`${e.isDisabled ? classes.disabled : ""} : ${
          classes.carouselBtn
        } ${classes.leftBtn}`}
      />
    );
  }
  function renderNextButton(e) {
    return (
      <ArrowForwardIosIcon
        style={{ fontSize: "30" }}
        className={`${e.isDisabled ? classes.disabled : ""} : ${
          classes.carouselBtn
        } ${classes.rightBtn} `}
      />
    );
  }
  return (
    <section
      className={classes.hero}
      style={{ "--carousel-tint": props.tintColor }}
    >
      <AliceCarousel
        autoPlay
        autoPlayInterval={3000}
        animationType="fadeout"
        infinite
        mouseTracking
        items={displayCarouselItems(props.images)}
        renderDotsItem={renderDotsItem}
        renderPrevButton={renderPrevButton}
        renderNextButton={renderNextButton}
      />
    </section>
  );
}
