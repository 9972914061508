import classes from "./projects.module.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import projects from "../../../assets/data/ProjectsData";

function products(props) {
  return (
    <div className={classes.container}>
      <div className={classes.shade} />
      <div className={classes.products}>
        <h1 className={classes.title}>Our Projects</h1>
        <AliceCarousel
          className={classes.carousel}
          mouseTracking
          items={projects?.map((card) => (
            <div className={classes.cardWrapper}>
              <div className={classes.highlightCard}>
                <img src={card.img} className={classes.highlightImg} alt="h" />
                <h1>{card.title}</h1>
              </div>
            </div>
          ))}
          disableDotsControls={true}
          responsive={{
            0: {
              items: 1,
            },
            1000: {
              items: 2,
            },
            1024: {
              items: 3,
            },
          }}
          renderDotsItem={(e) => {
            return null;
          }}
          renderPrevButton={(e) => {
            return (
              <ArrowBackIosNewIcon
                style={{ fontSize: "30" }}
                className={
                  e.isDisabled ? classes.carouseLBtnDis : classes.carouseLBtn
                }
              />
            );
          }}
          renderNextButton={(e) => {
            return (
              <ArrowForwardIosIcon
                style={{ fontSize: "30" }}
                className={
                  e.isDisabled ? classes.carouselRtnDis : classes.carouselRBtn
                }
              />
            );
          }}
        />
      </div>
    </div>
  );
}

export default products;
