import classes from "./contactBox.module.css";

function ContactBox(props) {
  return (
    <div className={classes.info}>
      <div className={classes.data}>
        <h1>Factory {props.index}</h1>
        {props.info.map((item, index) => (
          <div key={index} className={classes.infoItem}>
            {item.icon}
            <h5>{item.data}</h5>
          </div>
        ))}
      </div>
      <div className={classes.container}>
        <iframe
          src={props.map} 
          height="200"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          title="location"
        />
      </div>
    </div>
  );
}

export default ContactBox;
