import classes from './servieces.module.css'
import servicesData from '../../../assets/data/ServicesData'


function Services() {
    return (
        <div className={classes.container}>
            {servicesData.map((service, index) => {
            return(
                <div key={index} className={classes.card}>
                    <img src={service.img} alt={service.title} key={index} />
                    <h3>{service.title}</h3>
                </div>
            )})}  
        </div>
    )
}

export default Services