import './App.css';
import { Routes, Route} from "react-router-dom";
import NavBar from "./layouts/nav/NavBar";
import Footer from "./layouts/footer/Footer";
import AboutUs from './pages/about/aboutUs';
import HomePage from "./pages//home/homePage";
import ProductsPage from './pages/products/ProductsPage';
import ContactUs from './pages/contact us/ContactUs';
import ProjectsPage from './pages/projects/ProjectsPage';

function App() {
  return (
    <>
    <NavBar />
    <Routes>
      <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUs />} />
      <Route path="/products" element={<ProductsPage />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/projects" element={<ProjectsPage />} />

    </Routes>
    <Footer />
    </>
  );
}

export default App;
