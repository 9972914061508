import React, { useState} from "react";
import NavData from "../../assets/data/NavData";
import classes from "./navbar.module.css";
import { NavLink } from "react-router-dom";
import logo from "../../assets/brand/logo.svg";
import { RiMenu3Fill } from "react-icons/ri";
import Overlay from "../../generic components/overlay/Overlay.tsx";

const NavBar = () => {
  const [showSide, setShowSide] = useState(false);

  return (
    <>
      <nav className={classes.nav}>
        <NavLink to="/" className={classes.brand}>
          <img src={logo} style={{ width: "15rem" }} alt="logo" />
        </NavLink>
        <RiMenu3Fill
          className={classes.menu}
          onClick={() => setShowSide(true)}
        />
        <ul className={classes.NavLinks}>
          {NavData.map((item, index) => {
            return (
              <li key={index}>
                <NavLink
                  to={item.path}
                  activeClassName={classes.active}
                  className={classes.navLink}
                >
                  {item.title}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className={classes.overlay}>
        <Overlay
          open={showSide}
          onClose={() => setShowSide(false)}
          position="left"
        >
          <nav>
            <ul className={classes.mobileList}>
              {NavData.map((item, index) => {
                return (
                  <li key={index}>
                    <NavLink
                      to={item.path}
                      activeClassName={classes.active}
                      className={classes.navLink}
                      onClick={() => setShowSide(false)}
                    >
                      {item.title}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
            <div></div>
          </nav>
        </Overlay>
      </div>
    </>
  );
};

export default NavBar;
