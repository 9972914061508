import React from "react";
import classes from "./footer.module.css";
import logo from "../../assets/brand/logo.svg";
import FooterData from "../../assets/data/FooterData";
import facebook from "../../assets/imgs/icons/facebook.svg";
import instagram from "../../assets/imgs/icons/instagram.svg";
import pinterest from "../../assets/imgs/icons/pinterest.svg";
import { FaFacebookF } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { IoMailOutline } from "react-icons/io5";
import { CiPhone } from "react-icons/ci";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <div className={classes.logo}>
        <img src={logo} alt="logo" />
        <p>
          Engineering group for industry & contracting Another stretch of years
          of experience gained in the steel industry with the entry into a new
          challenge in the field of Design, manufacture and installation of
          electromechanical works for drinking water, sewage and irrigation
        </p>
      </div>

      <div className={classes.data}>
        {FooterData.map((data, index) => {
          return (
            <div key={index}>
              <h3>{data.header}</h3>
              <ul>
                {data.links.map((link, index) => {
                  return (
                    <li key={index}>
                      <a href={link.link}>{link.title}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
      <div className={classes.contact}>
        <div className={classes.contactInfo}>
          <h3>Contact Us</h3>
          <a href="mailto:info@egindustry.com">
            <IoMailOutline /> info@egindustry.com
          </a>
          <a>
            <CiPhone /> +2 010 655 29542
          </a>
        </div>

        <h3>Follow Us</h3>
        <div className={classes.socials}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/"
          >
            <FaFacebookF />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/"
          >
            <AiFillInstagram />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/"
          >
            <FaLinkedinIn />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
