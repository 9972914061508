import classes from "./machinery.module.css";
import "../../../index.css";
import MachineryData from "../../../assets/data/MachineryData";

function Machinery() {
  return (
    <div className={classes.machinery}>
      <h1 className="title">Machinery</h1>
      <div className={classes.cardWrapper}>
        
      {MachineryData.map((machinery, index) => {
        return (
          <div key={index} className={classes.card}>
            <img src={machinery.img} alt={machinery.title} />
            <h2>{machinery.title}</h2>
            <p>{machinery.text}</p>
          </div>
        );
      })}
      </div>
    </div>
  );
}

export default Machinery;
