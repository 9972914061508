import React, { useEffect } from "react";
import classes from "./products.module.css";
import PageHeader from "../../generic components/pageHeader/PageHeader";
import products from "../../assets/data/ProductsData";

function ProductsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.productsPage}>
      <PageHeader title="Products" />
      <div className={`${classes.cardsWrapper} animate__animated animate__fadeInUp`}>
        {products.map((product, index) => {
          return (
            <div key={index} className={classes.highlightCard}>
              <img src={product.img} className={classes.highlightImg} alt="h" />
              <div className={classes.highlightInfo}>
                <h2>{product.title}</h2>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProductsPage;
