import img1 from "../imgs/partners/elsewedy.png";
import img2 from "../imgs/partners/hassanAllam.png";
import img3 from "../imgs/partners/orascom.png";
import img4 from "../imgs/partners/moqawelon.png";
import img5 from "../imgs/partners/hitachi.png";
import img6 from "../imgs/partners/metito.png";
import img7 from "../imgs/partners/concord.png";
import img8 from "../imgs/partners/edecs.jpg";

const partners = [
  {
    img: img1,
  },
  {
    img: img2,
  },
  {
    img: img3,
  },
  {
    img: img4,
  },
  {
    img: img5,
  },
  {
    img: img6,
  },
  {
    img: img7,
  },
  {
    img: img8,
  },
];

export default partners;
